import React from "react"
import styled from "styled-components"

export default () => {
  const items = [
    {
      title: `Experiences`,
      description: `Build a personalized website experience for every visitor.`,
      link: `https://useproof.com/experiences`,
    },
    {
      title: `Pulse`,
      description: `Increase credibility and boost conversions with social proof.`,
      link: `https://useproof.com/pulse`,
    },
  ]
  return (
    <List>
      {items.map(({ title, description, link }, index) => (
        <Item key={index}>
          <Wrapper
            href={link}
            aria-label={`Discover Proof ${title}`}
            index={index}
            className={title.toLowerCase()}
          >
            {index === 0 ? (
              <Icon
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 40 40"
              >
                <circle cx="20" cy="20" r="20" fill="#EAEDFF" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M26.41 11.16l1.913 1.93-6.693 6.755 6.693 6.755-1.912 1.93-6.694-6.755-6.693 6.755-1.913-1.93 6.694-6.755-6.694-6.755 1.913-1.93 6.693 6.755 6.694-6.755z"
                  fill="#1E43FF"
                />
                <path
                  d="M18.793 25.36a1.343 1.343 0 011.912 0 1.373 1.373 0 010 1.93l-5.592 5.644a1.343 1.343 0 01-1.913 0 1.373 1.373 0 010-1.93l5.593-5.644zM24.141 7.066a1.344 1.344 0 011.913 0 1.374 1.374 0 010 1.93L20.46 14.64a1.343 1.343 0 01-1.912 0 1.373 1.373 0 010-1.93l5.592-5.644z"
                  fill="#1E43FF"
                />
              </Icon>
            ) : (
              <Icon
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 36 36"
              >
                <circle cx="18" cy="18" r="18" fill="#FFE0F1" />
                <path
                  d="M24.5 15a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"
                  fill="#FF1493"
                />
                <path
                  d="M17.395 27C10.805 27 9 25.196 9 18.605 9 12.014 10.154 9.8 16.744 9.8c.453 0 .877.01 1.284.027A.336.336 0 0118 10.5a29.08 29.08 0 00-1.256-.027c-6.208 0-7.072 1.924-7.072 8.132s1.515 7.723 7.723 7.723 7.724-1.062 7.724-7.27c0-.443-.01-.857-.027-1.256a.337.337 0 01.32-.35c.186.003.35.322.35.322.018.407.029.83.029 1.284 0 6.59-1.805 7.942-8.396 7.942z"
                  fill="#FF1493"
                  stroke="#FF1493"
                  strokeWidth="1.5"
                  strokeLinejoin="round"
                />
              </Icon>
            )}
            <Content>
              <Title>{title}</Title>
              <Description>{description}</Description>
            </Content>
          </Wrapper>
        </Item>
      ))}
    </List>
  )
}

// Styled Components
const List = styled.ul`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-row-gap: 1.5rem;
  @media (min-width: 1200px) {
    grid-row-gap: 0;
  }
`
const Item = styled.li``
const Wrapper = styled.a`
  display: grid;
  grid-template-columns: minmax(0, 2rem) minmax(0, 1fr);
  align-items: start;
  grid-column-gap: 1rem;
  padding: 0;
  transition: all 0.2s ease;
  &.experiences {
    color: ${props => props.theme.color.blue["400"]};
    @media (min-width: 992px) {
      &:hover {
        background-color: ${props => props.theme.color.blue["50"]};
      }
    }
  }
  &.pulse {
    color: ${props => props.theme.color.pink["400"]};
    @media (min-width: 992px) {
      &:hover {
        background-color: ${props => props.theme.color.pink["50"]};
      }
    }
  }
  @media (min-width: 992px) {
    padding: 0.75rem;
  }
`
const Content = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-row-gap: 0.25rem;
  line-height: 1.5;
`
const Icon = styled.svg`
  width: 2rem;
`
const Title = styled.div``
const Description = styled.div`
  color: ${props => props.theme.color.neutral["600"]};
`
