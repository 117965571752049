import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// Components

const Wrapper = ({ children, pathPrefix }) => {
  return !pathPrefix ? <Title>{children}</Title> : <>{children}</>
}

export default ({ pathPrefix, pageNumber, numberOfPages }) => {
  const title =
    pageNumber === 0
      ? `Proof Blog`
      : `Proof Blog Archives - Page ${pageNumber + 1} of ${numberOfPages}`

  return (
    <Wrapper pathPrefix={pathPrefix}>
      <Button to={`/`} aria-label="Proof Blog, Back to homepage">
        <svg
          role={"img"}
          width="97"
          height="32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {!pathPrefix && <title>{title}</title>}
          <path
            d="M10 12h19.6a10 10 0 01-9.8 8H0a10 10 0 019.8-8h.2zm19.6-4a10 10 0 00-9.8-8h-10v8h19.8zM9.8 32a10 10 0 009.8-8H9.8v8z"
            fill="url(#paint0_linear)"
          />
          <path
            d="M52.17 17.968c0 3.637-2.52 6-5.938 6-1.5 0-2.802-.5-3.75-1.576v5.673H39.75V12.12h2.605v1.533c.92-1.118 2.28-1.665 3.878-1.665 3.417.012 5.937 2.367 5.937 5.98zm-2.782 0c0-2.233-1.5-3.658-3.46-3.658s-3.485 1.425-3.485 3.658c0 2.232 1.5 3.66 3.485 3.66 1.985 0 3.46-1.425 3.46-3.66zM60.67 12v2.595a3.403 3.403 0 00-.635-.065c-2.083 0-3.395 1.225-3.395 3.615v5.672h-2.75V12.12h2.61v1.708C57.3 12.602 58.72 12 60.67 12zM61.48 17.968c0-3.5 2.63-5.98 6.223-5.98 3.637 0 6.25 2.475 6.25 5.98 0 3.505-2.606 6-6.25 6-3.593.002-6.223-2.495-6.223-6zm9.705 0c0-2.233-1.5-3.658-3.483-3.658-1.982 0-3.452 1.425-3.452 3.658 0 2.232 1.5 3.66 3.463 3.66 1.962 0 3.472-1.425 3.472-3.66zM75.173 17.968c0-3.5 2.63-5.98 6.222-5.98 3.635 0 6.25 2.475 6.25 5.98 0 3.505-2.607 6-6.25 6-3.595.002-6.222-2.495-6.222-6zm9.705 0c0-2.233-1.5-3.658-3.483-3.658-1.982 0-3.462 1.425-3.462 3.658 0 2.232 1.5 3.66 3.462 3.66 1.963 0 3.483-1.425 3.483-3.66zM92.062 11.5v.703h3.22v2.19h-3.155v9.424H89.39V11.463c0-2.456 1.5-4.053 4.207-4.053.94 0 1.84.197 2.453.635l-.768 2.06a2.565 2.565 0 00-1.5-.483c-1.127 0-1.72.628-1.72 1.878z"
            fill="#000A3F"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="14.8"
              y1="0"
              x2="14.8"
              y2="32"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF1493" />
              <stop offset=".39" stopColor="#FD576C" />
              <stop offset=".81" stopColor="#FC9847" />
              <stop offset="1" stopColor="#FBB138" />
            </linearGradient>
          </defs>
        </svg>
      </Button>
    </Wrapper>
  )
}

// Styled Components\
const Button = styled(Link)`
  margin-right: 2rem;
  display: flex;
`
const Title = styled.h1``
