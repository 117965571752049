import yCombinator from "../../../images/y-combinator.svg"

const products = {
  title: `Products`,
  items: [
    {
      type: `text`,
      title: `Experiences`,
      link: `https://useproof.com/experiences`,
    },
    {
      type: `text`,
      title: `Pulse`,
      link: `https://useproof.com/experiences`,
    },
  ],
}

const resources = {
  title: `Resources`,
  items: [
    {
      type: `text`,
      title: `Watch Show`,
      link: `https://useproof.com/scaleordie`,
    },
    {
      type: `text`,
      title: `Blog`,
      link: `https://blog.useproof.com`,
    },
    {
      type: `text`,
      title: `Playbooks`,
      link: `https://useproof.com/playbooks`,
    },
    {
      type: `text`,
      title: `Case Studies`,
      link: `https://useproof.com/customers`,
    },
  ],
}

const company = {
  title: `Company`,
  items: [
    {
      type: `text`,
      title: `We're Hiring`,
      link: `https://useproof.com/careers`,
    },
    {
      type: `text`,
      title: `Partners`,
      link: `https://proof.growsumo.com/`,
    },
  ],
}

const contact = {
  title: `Contact Us`,
  items: [
    {
      type: `list`,
      title: [`200 E. 6th Street`, `Suite 310`, `Austin, TX 78701`],
      link: `https://www.google.com/maps/place/Proof+Technologies+Inc/@30.267874,-97.741119,15z/data=!4m5!3m4!1s0x0:0x187d4cd4883ce274!8m2!3d30.267874!4d-97.741119`,
    },
    {
      type: `text`,
      title: `650-600-3985`,
      link: `tel:6506003985`,
    },
    {
      type: `text`,
      title: `team@useproof.com`,
      link: `mailto:team@useproof.com`,
    },
    {
      type: `image`,
      title: `Y Combinator Winter '18 Graduate`,
      link: `https://www.ycombinator.com`,
      src: yCombinator,
    },
  ],
}

export default [products, resources, company, contact]
