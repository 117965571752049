import React, { useEffect } from "react"
import styled, { ThemeProvider } from "styled-components"

// Fonts
import loadFonts from "../../fonts/index.js"

// Global Style
import GlobalStyle from "./globalStyle"

// Theme
import Theme from "./theme"

// Layout Components
import Header from "../header/index"
import Newsletter from "../newsletter/index"
import Footer from "../footer/index"

export default ({
  children,
  pathPrefix,
  pageNumber,
  numberOfPages,
  category,
}) => {
  useEffect(() => {
    loadFonts()
  })
  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <Header
        pathPrefix={pathPrefix}
        pageNumber={pageNumber}
        numberOfPages={numberOfPages}
        category={category}
      />
      <Main>{children}</Main>
      <Newsletter />
      <Footer />
    </ThemeProvider>
  )
}

// Styled Components
const Main = styled.main`
  padding-bottom: 2rem;
  padding-top: 7rem;
  @media (min-width: 992px) {
    padding-bottom: 3rem;
    padding-top: 8.5rem;
  }
`
