import { graphql, useStaticQuery } from "gatsby"

export default () => {
  const { site } = useStaticQuery(
    graphql`
      query useSiteCategories {
        site {
          siteMetadata {
            categories {
              name
              slug
            }
          }
        }
      }
    `
  )

  return site.siteMetadata.categories
}
