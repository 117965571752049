import React from "react"

// Layout Components
import Container from "../../container"

// Components
import Logo from "./logo"
import Column from "./column"

// Data
import data from "./data"

export default () => {
  return (
    <Container
      sm={`
                align-items: start;
                grid-template-columns: repeat(2, minmax(0,1fr));
                grid-row-gap:2rem;
                padding-top: 2rem;
                padding-bottom: 2rem;
            `}
      md={`
                grid-template-columns: repeat(4, minmax(0,1fr));
            `}
      lg={`
                grid-template-columns: minmax(0,16rem) repeat(4,minmax(0,11.25rem));
                grid-column-gap: 1rem;
                padding-top: 4rem;
                padding-bottom: 4rem;
            `}
    >
      <Logo />
      {data.map(({ title, items }, index) => (
        <Column key={index} title={title} items={items} />
      ))}
    </Container>
  )
}
