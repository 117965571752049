import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, code,
del, dfn, em, img, q, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
}

br {
    content: '';
    width:100%;
    display:block;
}

input, button, select, textarea, optgroup, option {
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
}

button {
    appearance:none;
    outline:0;
    border:none;
    background-color:transparent;
    cursor:pointer;
}

em {
	font-style: italic;
}

strong {
	font-weight: bold;
}

html {
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-feature-settings: 'kern' 1, 'dlig' 1, 'opbd' 1, 'ss01' 1;
    text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

html,
body {
	margin: 0;
	padding: 0;
	height: 100%;
}

body {
    background-color: #fff;
    font-family: Georgia, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: ${props => props.theme.color.neutral["600"]};
}

p {
    font-family: Georgia, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5;
}

.font-ready body {
    font-family: 'GT Walsheim Pro';
}

.font-ready p {
    font-family: 'GT Super Text';
}

*, :after, :before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

ul {
    list-style:none;
    margin:0;
    padding:0;
}

img {
    max-width:100%;
    height: auto;
}

h1, h2, h3, h4, h5, h6, blockquote {
    line-height:1.25;
    font-weight:500;
}

h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.3125rem;
}

h3 {
    font-size: 1.25rem;
}

h4 {
    font-size: 1.125rem;
}

h5 {
    font-size: 1.075rem;
}

h6 {
    font-size: 1.025rem;
}

blockquote > br {
    margin-top: 1rem;
    display: block;
    width: 100%;
    content: '';
}

a img {
	border: none;
	outline: none;
}

a {
    color: inherit;
	outline: none;
    text-decoration: none;
    line-height: inherit;
}

a,
a:active,
a:visited,
a img {
	outline: none;
}

.rc-pagination {
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
}

.rc-pagination-item {
    margin:0 0.25rem;
    border-radius:50%;
}

.rc-pagination-prev,  rc-pagination-next {

}

.rc-pagination-disabled {

}

.ReactModal__Overlay {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:#fff;
    overflow: auto;
    z-index: 9;
}

.modal--nav {
    .ReactModal__Overlay {
        z-index:2;
        transition: all .4s;
        transform: translate3d(0,-100%,0);
    }
    .ReactModal__Overlay--after-open {
        transform: translate3d(0,7rem,0);
    }
    .scroll {
        transform: translate3d(0,2.5rem,0);
    }
    .ReactModal__Overlay--before-close {
        transform: translate3d(0,-100%,0);
    }
}

.ReactModal__Body--open {
    overflow:hidden;
}

.ReactModal__Content {
    outline: 0;
    min-height:100%;
    height: 100%;
}

.post-content {
    @media (min-width:992px) {
        & > {
            p, h1, h2, h3, h4, h5, h6, blockquote, ul, ol, span, table {
                max-width:650px;
                width:100%;
                margin:0 auto;
            }
        }
    }
}

@media (min-width:992px) {
    .rc-pagination-item { 
        margin:0 0.5rem;
    }
    p {
        font-size: 1.125rem;
    }
    h1 {
        font-size: 3rem;
    }
    
    h2 {
        font-size: 1.5rem;
    }
    
    h3 {
        font-size: 1.3125rem;
    }
    
    h4 {
        font-size: 1.25rem;
    }

    h5 {
        font-size: 1.125rem;
    }

    h6 {
        font-size: 1.075rem;
    }
}
`
