import path from "path"
import React from "react"
import Helmet from "react-helmet"
import SchemaOrg from "./schemaOrg"

// Fonts
import preloadFontA from "../../fonts/GT-Super-Text-Regular.woff2"
import preloadFontB from "../../fonts/GT-Walsheim-Pro-Regular.woff2"
import preloadFontC from "../../fonts/GT-Walsheim-Pro-Medium.woff2"
import preloadFontD from "../../fonts/GT-Walsheim-Pro-Bold.woff2"

// Hooks
import useSiteMeta from "../../hooks/useSiteMeta"

export default ({ 
  isBlogPost, title, description, 
  slug, image, imageDimensions, date,
  paged, noindex
}) => {
  const { siteMetadata: seo, banner, logo } = useSiteMeta()

  const baseUrl = seo.canonicalUrl

  const metaTitle = title || seo.title
  const metaDescription = description || seo.description
  const metaImage = image ? `${baseUrl}${image}` : `${baseUrl}${banner.publicURL}`
  const metaImageDimensions = imageDimensions ? imageDimensions : !image ? banner.childImageSharp.original : false

  const url = slug ? `${baseUrl}${path.sep}${slug}` : baseUrl

  const datePublished = isBlogPost ? date : false

  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          lang: `en`,
        }}
      >
        {/* General tags */}
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="image" content={metaImage} />

        {/* No Index */}
        {noindex && <meta name="robots" content="noindex"></meta>}

        {/* Cannocial */}
        {paged && <link rel="canonical" href={`${url}/page/${paged}`}/>}

        {/* OpenGraph tags */}
        <meta property="og:url" content={url} />
        {isBlogPost ? (
          <meta property="og:type" content="article" />
        ) : (
          <meta property="og:type" content="website" />
        )}
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={metaImage} />
        {metaImageDimensions && <meta property="og:image:width" content={`${metaImageDimensions.width}`} />}
        {metaImageDimensions && <meta property="og:image:height" content={`${metaImageDimensions.height}`} />}
        <meta property="fb:app_id" content={seo.social.fbAppID} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={seo.social.twitter} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={metaImage} />
        <link
          rel="preload"
          as="font"
          href={preloadFontA}
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          as="font"
          href={preloadFontB}
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          as="font"
          href={preloadFontC}
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          as="font"
          href={preloadFontD}
          type="font/woff2"
          crossOrigin="anonymous"
        />
      </Helmet>
      <SchemaOrg
        isBlogPost={isBlogPost}
        url={url}
        title={metaTitle}
        sameAs={seo.social.links}
        image={metaImage}
        description={metaDescription}
        datePublished={datePublished}
        canonicalUrl={baseUrl}
        author={seo.author}
        organization={seo.organization}
        logo={logo}
        defaultTitle={seo.title}
      />
    </React.Fragment>
  )
}
