export const style = {
  overlay: {
    position: ``,
    left: ``,
    right: ``,
    bottom: ``,
    top: ``,
    background: ``,
  },
  content: {
    position: ``,
    maxWidth: ``,
    width: ``,
    margin: ``,
    border: ``,
    padding: ``,
    outline: ``,
    borderRadius: ``,
    background: ``,
    left: ``,
    right: ``,
    bottom: ``,
    top: ``,
    overflow: ``,
  },
}
