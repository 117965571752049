import React from "react"
import styled from "styled-components"

export default () => {
  return (
    <List>
      <Item>
        <Link
          href={`https://twitter.com/useproof`}
          aria-label={`Visit Proof's Twitter Page`}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M15.3333 2.00011C14.6949 2.45043 13.9881 2.79485 13.24 3.02011C12.8385 2.55845 12.3049 2.23124 11.7113 2.08273C11.1178 1.93422 10.493 1.97158 9.92138 2.18975C9.34977 2.40792 8.85895 2.79638 8.51531 3.30259C8.17167 3.8088 7.99179 4.40834 7.99999 5.02011V5.68678C6.82841 5.71716 5.66751 5.45732 4.62066 4.93041C3.57382 4.4035 2.67353 3.62587 1.99999 2.66678C1.99999 2.66678 -0.666677 8.66678 5.33332 11.3334C3.96034 12.2654 2.32476 12.7327 0.666656 12.6668C6.66666 16.0001 14 12.6668 14 5.00011C13.9994 4.81441 13.9815 4.62918 13.9467 4.44678C14.6271 3.77577 15.1072 2.92859 15.3333 2.00011Z" />
          </svg>
        </Link>
      </Item>
      <Item>
        <Link
          href={`https://www.facebook.com/useproof/`}
          aria-label={`Visit Proof's Facebook Page`}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12 1.33325H9.99999C9.11593 1.33325 8.26809 1.68444 7.64297 2.30956C7.01785 2.93468 6.66666 3.78253 6.66666 4.66659V6.66659H4.66666V9.33325H6.66666V14.6666H9.33332V9.33325H11.3333L12 6.66659H9.33332V4.66659C9.33332 4.48977 9.40356 4.32021 9.52859 4.19518C9.65361 4.07016 9.82318 3.99992 9.99999 3.99992H12V1.33325Z" />
          </svg>
        </Link>
      </Item>
      <Item>
        <Link
          href={`https://www.linkedin.com/company/useproof/`}
          aria-label={`Visit Proof's Linkedin Page`}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10.6667 5.33325C11.7275 5.33325 12.7449 5.75468 13.4951 6.50482C14.2452 7.25497 14.6667 8.27239 14.6667 9.33325V13.9999H12V9.33325C12 8.97963 11.8595 8.64049 11.6095 8.39044C11.3594 8.14039 11.0203 7.99992 10.6667 7.99992C10.313 7.99992 9.9739 8.14039 9.72385 8.39044C9.4738 8.64049 9.33332 8.97963 9.33332 9.33325V13.9999H6.66666V9.33325C6.66666 8.27239 7.08808 7.25497 7.83823 6.50482C8.58838 5.75468 9.60579 5.33325 10.6667 5.33325Z" />
            <path d="M4.00001 6H1.33334V14H4.00001V6Z" />
            <path d="M2.66668 3.99992C3.40306 3.99992 4.00001 3.40296 4.00001 2.66659C4.00001 1.93021 3.40306 1.33325 2.66668 1.33325C1.9303 1.33325 1.33334 1.93021 1.33334 2.66659C1.33334 3.40296 1.9303 3.99992 2.66668 3.99992Z" />
          </svg>
        </Link>
      </Item>
    </List>
  )
}

// Styled Components
const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, max-content));
  grid-row-gap: 1.5rem;
  grid-column-gap: 1.5rem;
`
const Item = styled.li``
const Link = styled.a`
  fill: ${props => props.theme.color.neutral["400"]};
  display: flex;
  width: 1.5rem;
  &:hover {
    fill: #fff;
  }
`
