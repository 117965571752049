import React from "react"
import styled from "styled-components"

export default () => {
  return (
    <Column>
      <Logo
        width="32"
        height="35"
        viewBox="0 0 32 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32 8.649A10.81 10.81 0 0021.405 0h-10.81v8.649H32zm-21.405 4.324A10.81 10.81 0 000 21.622h21.405A10.811 10.811 0 0032 12.973H10.595zm6.838 19.185a10.811 10.811 0 01-6.838 2.437v-8.65h10.594a10.811 10.811 0 01-3.756 6.213z"
          fill="url(#paint1_linear)"
        />
        <defs>
          <linearGradient
            id="paint1_linear"
            x1="16"
            y1="0"
            x2="16"
            y2="34.595"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF1493" />
            <stop offset="1" stopColor="#FBB138" />
          </linearGradient>
        </defs>
      </Logo>
      <Title>{`Make the internet delightfully human.`}</Title>
    </Column>
  )
}

// Styled Components
const Logo = styled.svg`
  margin: 0 auto;
  @media (min-width: 992px) {
    margin: 0;
  }
`
const Title = styled.div`
  font-size: 1.5rem;
  line-height: 1.33;
`
const Column = styled.div`
  display: grid;
  grid-column: 1/3;
  grid-row-gap: 2rem;
  text-align: center;
  border-bottom: 1px solid ${props => props.theme.color.neutral["600"]};
  padding-bottom: 2rem;
  @media (min-width: 768px) {
    grid-column: 1/5;
  }
  @media (min-width: 992px) {
    grid-column: inherit;
    grid-row-gap: 2rem;
    text-align: left;
    border-bottom: none;
    padding-bottom: 0;
  }
`
