import React from "react"
import styled from "styled-components"

export default ({ title, items }) => {
  return (
    <Column>
      <Title>{title}</Title>
      <List>
        {items.map(({ title, link, type, src }, index) => (
          <Item key={index}>
            <Link href={link}>
              {type === "text" ? (
                title
              ) : type === "list" ? (
                title.map((string, index) => {
                  if (index === title.length - 1) {
                    return string
                  }
                  return (
                    <React.Fragment key={index}>
                      {string}
                      <br />
                    </React.Fragment>
                  )
                })
              ) : type === "image" ? (
                <Img src={src} alt={title} className={`y-combinator-logo`} />
              ) : null}
            </Link>
          </Item>
        ))}
      </List>
    </Column>
  )
}

// Styled Components
const Column = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-row-gap: 0.5rem;
`
const Title = styled.h3`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
`
const List = styled.ul`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-row-gap: 0.5rem;
`
const Item = styled.li`
  font-size: 0.875rem;
  line-height: 1.75;
  br {
    height: 100%;
  }
`
const Link = styled.a`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  color: ${props => props.theme.color.neutral["400"]};
  &:hover {
    color: #fff;
  }
`
const Img = styled.img`
  &.y-combinator-logo {
    margin-top: 2rem;
    height: 1.5rem;
  }
`
