import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import classNames from "classnames"

// Components
import Top from "./top/index"
import Bottom from "./bottom/index"

export default ({ pathPrefix, pageNumber, numberOfPages, category }) => {
  const header = useRef(null)

  const [fixed, setFixed] = useState(false)
  const [show, setShow] = useState(false)
  const [scrollPos, setScrollPos] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      const curScroll = window.scrollY

      const pastHeader = curScroll > header.current.clientHeight / 2

      setScrollPos(curScroll)

      setFixed(pastHeader)

      setShow(pastHeader ? curScroll < scrollPos : false)
    }

    document.addEventListener("scroll", handleScroll)

    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [scrollPos, show, fixed, header])

  const headerClass = classNames({
    fixed: fixed,
    scroll: show,
  })
  return (
    <Header ref={header} className={headerClass}>
      <Top
        pathPrefix={pathPrefix}
        pageNumber={pageNumber}
        numberOfPages={numberOfPages}
        fixed={fixed}
        show={show}
      />
      <Bottom pathPrefix={pathPrefix} category={category} />
    </Header>
  )
}

// Styled Components
const Header = styled.header`
  z-index: 9;
  background-color: ${props => props.theme.color.neutral['10']};
  position: fixed;
  left: 0;
  right: 0;
  transition: all 0.8s;
  transform: translate3d(0, 0, 0);
  transform-style: preserve-3d;
  &.fixed {
    transform: translate3d(0, -7rem, 0);
    @media (min-width: 992px) {
      transform: translate3d(0, -9.5rem, 0);
    }
  }
  &.scroll {
    transform: translate3d(0, -4.5rem, 0);
    @media (min-width: 992px) {
      transform: translate3d(0, -5.5rem, 0);
    }
  }
`
