import { graphql, useStaticQuery } from "gatsby"

export default () => {
  const { file } = useStaticQuery(
    graphql`
      query useResourcesBg {
        file(relativePath: { eq: "resources.jpg" }) {
          childImageSharp {
            fixed(width: 192) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `
  )

  return file.childImageSharp.fixed
}
