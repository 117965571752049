import Observer from "fontfaceobserver"

import "./fonts.css"

export default () => {
  const fontA = new Observer("GT Super Text")
  const fontB = new Observer("GT Walsheim Pro", {
    weight: 400,
  })
  const fontC = new Observer("GT Walsheim Pro", {
    weight: 500,
  })
  const fontD = new Observer("GT Walsheim Pro", {
    weight: 700,
  })

  Promise.all([fontA.load(), fontB.load(), fontC.load(), fontD.load()]).then(
    function() {
      document.documentElement.classList.add("font-ready")
    }
  )
}
