import { graphql, useStaticQuery } from "gatsby"

export default () => {
  const {
    site: { siteMetadata },
    banner,
    logo,
  } = useStaticQuery(
    graphql`
      query useSiteMetaQuery {
        site {
          siteMetadata {
            title
            description
            canonicalUrl
            author {
              name
            }
            organization {
              name
              url
            }
            social {
              twitter
              fbAppID
              links
            }
          }
        }
        banner: file(relativePath: { eq: "banner.png" }) {
          publicURL
          childImageSharp {
            original {
              width
              height
            }
          }
        }
        logo: file(relativePath: { eq: "icon.png" }) {
          publicURL
        }
      }
    `
  )

  return {
    siteMetadata,
    logo: logo.publicURL,
    banner: banner,
  }
}
