import React from "react"
import styled from "styled-components"

export default () => {
  const items = [
    {
      title: `Pulse Login`,
      link: `https://go.useproof.com/`,
    },
    {
      title: `Experiences Login`,
      link: `http://x.useproof.com/`,
    },
  ]
  return (
    <List>
      {items.map(({ title, link }, index) => (
        <Item key={index}>
          <Wrapper href={link}>{title}</Wrapper>
        </Item>
      ))}
    </List>
  )
}

// Styled Components
const List = styled.ul``
const Item = styled.li`
  width: 100%;
`
const Wrapper = styled.a`
  display: block;
  line-height: 1.5;
  padding: 0.625rem 1.25rem;
  color: ${props => props.theme.color.blue["400"]};
`
