import React from "react"
import styled from "styled-components"

export default ({ items }) => {
  return (
    <List>
      {items.map(({ title, link }, index) => (
        <Item key={index}>
          <Button href={link}>{title}</Button>
        </Item>
      ))}
    </List>
  )
}

// Styled Components
const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-row-gap: 1rem;
  padding-top: 1.5rem;
  border-top: 1px solid ${props => props.theme.color.neutral["300"]};
`
const Item = styled.li``
const Button = styled.a`
  font-size: 1rem;
  color: ${props => props.theme.color.blue["400"]};
`
