import React, { useState, useRef } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import classNames from "classnames"

// Hooks
import useSiteCategories from "../../../hooks/useSiteCategories"

// Utils
import handleElementClose from "../../../utils/handleElementClose"

export default ({ pathPrefix, category }) => {
  const categories = useSiteCategories()

  const isCategory = categories.find(({ slug }) => slug === pathPrefix)

  const findActive = () => {
    if (isCategory) {
      return isCategory
    }

    if (category) {
      return category
    }

    return {
      name: `All`,
      slug: ``,
    }
  }

  const activeItem = findActive()

  const [isOpen, setOpen] = useState(false)

  const handleToggle = () => {
    setOpen(!isOpen)
  }

  const button = useRef(null)
  const nav = useRef(null)

  handleElementClose({
    elements: [button, nav],
    state: isOpen,
    setState: setOpen,
    hiddenDesktop: true,
  })

  const className = classNames({
    active: isOpen,
  })
  return (
    <>
      <Toggle
        ref={button}
        onClick={handleToggle}
        aria-label={`Toggle Category Navigation Menu`}
      >
        {activeItem.name}
        <Chevron
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 10 6"
          className={className}
        >
          <path
            d="M1 1L5 5L9 1"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </Chevron>
      </Toggle>
      <Nav
        ref={nav}
        aria-label={`Category Navigation Menu`}
        className={className}
      >
        <List>
          {categories.map(({ name, slug }, index) => (
            <Item key={index} className={name === activeItem.name && `active`}>
              <Button to={`/${slug}`}>{name}</Button>
            </Item>
          ))}
        </List>
      </Nav>
      <Search
        to="/search/"
        state={{
          modal: true,
        }}
        aria-label={`Open Search Menu`}
      >
        <Icon
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.0001 14L11.1001 11.1"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </Icon>
      </Search>
    </>
  )
}

// Styled Components
const Nav = styled.nav`
  position: relative;
  height: auto;
  width: 100%;
  overflow: hidden;
  grid-row: 2;
  transition: all 0.4s;
  max-height: 0;
  &.active {
    max-height: 20rem;
  }
  @media (min-width: 992px) {
    height: 100%;
    width: fit-content;
    grid-row: inherit;
    max-height: initial;
  }
`
const Toggle = styled.a`
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  width: fit-content;
  cursor: pointer;
  height: 100%;
  @media (min-width: 992px) {
    display: none;
  }
`
const Chevron = styled.svg`
  width: 0.75rem;
  fill: none;
  margin-left: 0.5rem;
  stroke: ${props => props.theme.color.neutral["400"]};
  &.active {
    transform: rotate(-180deg);
  }
`
const List = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding: 0 0 0.5rem 0;
  @media (min-width: 992px) {
    padding: 0;
  }
`
const Item = styled.li`
  height: 100%;
  width: 100%;
  &.active {
    display: none;
  }
  @media (min-width: 992px) {
    margin-right: 1rem;
    width: auto;
    &.active {
      display: block;
    }
  }
`
const Button = styled(Link)`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  box-shadow: none;
  font-size: 1rem;
  font-weight: 500;
  color: ${props => props.theme.color.neutral["600"]};
  outline: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  @media (min-width: 992px) {
    padding: 0 1.3125rem;
    &:hover,
    .active && {
      color: ${props => props.theme.color.blue["400"]};
    }
    .active && {
      box-shadow: ${props => props.theme.color.blue["400"]} 0px -3px 0px 0px inset;
    }
  }
`
const Search = styled(Link)`
  justify-self: flex-end;
  margin-left: auto;
  display: flex;
  height: 100%;
  @media (min-width: 1200px) {
    margin-left: 0;
    padding: 0 1.5rem;
  }
`
const Icon = styled.svg`
  height: 100%;
  cursor: pointer;
  stroke: ${props => props.theme.color.neutral["600"]};
  ${Search}:hover & {
    stroke: ${props => props.theme.color.blue["400"]};
  }
`
