import { useEffect } from "react"

// export default (refs, cb, state, hiddenDesktop) => {
export default ({ elements, state, setState, hiddenDesktop }) => {
  useEffect(() => {
    const isDefined = typeof window !== `undefined`

    const handleResize = () => {
      if (window.innerWidth > 992) {
        setState(false)
      }
    }

    const handleScroll = () => {
      if (state) {
        setState(false)
      }
    }

    const handleClick = e => {
      if (state) {
        const isParent = elements.some(({ current }) =>
          current.contains(e.target)
        )

        if (!isParent) {
          setState(false)
        }
      }
    }

    if (isDefined && hiddenDesktop) {
      window.addEventListener("resize", handleResize)
    }

    document.addEventListener("mousedown", handleClick)
    document.addEventListener("scroll", handleScroll)

    return () => {
      document.removeEventListener("mousedown", handleClick)
      document.removeEventListener("scroll", handleScroll)

      if (isDefined && hiddenDesktop) {
        window.removeEventListener("resize", handleResize)
      }
    }
  }, [elements, state, setState, hiddenDesktop])
}
