import React from "react"
import styled from "styled-components"

// Data
const items = [
  {
    title: `Cookies`,
    link: `https://useproof.com/services-agreement`,
  },
  {
    title: `Privacy`,
    link: `https://useproof.com/privacy`,
  },
  {
    title: `Terms`,
    link: `https://useproof.com/terms`,
  },
]

// Components

export default () => {
  const year = new Date().getFullYear()

  return (
    <List>
      {items.map(({ title, link }, index) => (
        <Item key={index}>
          <Link href={link}>{title}</Link>
        </Item>
      ))}
      <Item>{`@ ${year} Proof Technologies, Inc.`}</Item>
    </List>
  )
}

// Styled Components
const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, max-content));
  grid-row-gap: 1.5rem;
  grid-column-gap: 2rem;
  font-size: 0.875rem;
  line-height: 1.5;
  @media (min-width: 768px) {
    justify-content: end;
    grid-template-columns: repeat(4, minmax(0, max-content));
  }
`
const Item = styled.li`
  &:last-child {
    grid-column: 1/5;
  }
  @media (min-width: 768px) {
    &:last-child {
      grid-column: inherit;
    }
  }
`
const Link = styled.a`
  color: ${props => props.theme.color.neutral["400"]};
  &:hover {
    color: #fff;
  }
`
