import React, { useState, useRef } from "react"
import styled from "styled-components"
import classNames from "classnames"

// Utils
import handleElementClose from "../../../utils/handleElementClose"

// Components
import Login from "./login"

// High Order Components
const SubMenu = ({ label, children }) => {
  const wrapper = useRef(null)

  const [isOpen, setOpen] = useState(false)

  const className = classNames("secondary", {
    visible: isOpen,
  })

  const toggleOpen = () => {
    setOpen(!isOpen)
  }

  handleElementClose({
    elements: [wrapper],
    state: isOpen,
    setState: setOpen,
  })

  const aria = {
    label: `${label} Navigation Menu`,
    selector: `${label.toLowerCase()}-navigation`,
  }
  return (
    <Item ref={wrapper} role={`menu`} aria-label={aria.label}>
      <Button
        onClick={toggleOpen}
        aria-haspopup={`menu`}
        aria-expanded={isOpen}
        aria-controls={aria.selector}
        className={className}
      >
        {label}
      </Button>
      <SubNav className={className} id={aria.selector}>
        {children}
      </SubNav>
    </Item>
  )
}

export default () => {
  return (
    <List>
      <Item>
        <Button
          href={`https://useproof.com/pulse?utm_source=blog&utm_campaign=blog_organic&utm_medium=organic&utm_content=blog_nav`}
          className={`primary`}
        >
          {`Increase my conversions`}
        </Button>
      </Item>
      <SubMenu label={`Login`}>
        <Login />
      </SubMenu>
    </List>
  )
}

// Styled Components
const List = styled.ul`
  margin-left: auto;
  justify-self: flex-end;
  display: none;
  @media (min-width: 992px) {
    display: grid;
    grid-column-gap: 2rem;
    grid-template-columns: repeat(2, minmax(0, max-content));
    align-items: center;
  }
`
const Item = styled.li`
  height: 3rem;
`
const Button = styled.a`
  transition: background-color 0.3s ease;
  color: #fff;
  padding: 0 1.5rem;
  height: 100%;
  border-radius: 0;
  font-size: 1rem;
  font-weight: 400;
  &.primary {
    background-color: ${props => props.theme.color.blue["400"]};
    &:hover {
      background-color: ${props => props.theme.color.blue["600"]};
    }
  }
  &.secondary {
    cursor: pointer;
    background-color: transparent;
    color: ${props => props.theme.color.neutral["600"]};
    border: 1px solid ${props => props.theme.color.neutral["400"]};
    &:hover {
      border-color: ${props => props.theme.color.neutral["600"]};
    }
  }
  @media (min-width: 992px) {
    display: flex;
    align-items: center;
  }
`
const SubNav = styled.nav`
  display: none;
  @media (min-width: 992px) {
    display: block;
    position: absolute;
    right: 0rem;
    top: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.1s;
    background-color: #fff;
    border: 1px solid ${props => props.theme.color.neutral["300"]};
    box-shadow: 0 8px 24px 0 rgba(52, 51, 62, 0.1);
    width: fit-content;
    padding: 1.25rem 1.5rem;
    z-index: 3;
    &.visible {
      visibility: visible;
      opacity: 1;
    }
    :before {
      content: "";
      display: block;
      position: absolute;
      top: -1rem;
      right: 2rem;
      border-left: 1rem solid transparent;
      border-right: 1rem solid transparent;
      border-bottom: 1rem solid ${props => props.theme.color.neutral["300"]};
    }
  }
`
