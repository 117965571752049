import React, { useState, useRef } from "react"
import styled from "styled-components"
import classNames from "classnames"

// Utils
import handleElementClose from "../../../utils/handleElementClose"

// Components
import Products from "./products"
import Resources from "./resources"

// High Order Components
const SubMenu = ({ label, children }) => {
  const wrapper = useRef(null)

  const [isOpen, setOpen] = useState(false)

  const className = classNames({
    visible: isOpen,
  })

  const toggleOpen = () => {
    setOpen(!isOpen)
  }

  handleElementClose({
    elements: [wrapper],
    state: isOpen,
    setState: setOpen,
  })

  const aria = {
    label: `${label} Navigation Menu`,
    selector: `${label.toLowerCase()}-navigation`,
  }
  return (
    <Item ref={wrapper} role={`menu`} aria-label={aria.label}>
      <Button
        onClick={toggleOpen}
        aria-haspopup={`menu`}
        aria-expanded={isOpen}
        aria-controls={aria.selector}
        className={className}
      >
        {label}
        <Chevron
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 10 6"
          isOpen={isOpen}
        >
          <path
            d="M1 1L5 5L9 1"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </Chevron>
      </Button>
      <SubNav className={className} id={aria.selector}>
        {children}
      </SubNav>
    </Item>
  )
}

export default () => {
  return (
    <Nav aria-label={`Navigation Menu`}>
      <List>
        <SubMenu label={`Products`}>
          <Products />
        </SubMenu>
        <Item>
          <Button href={`https://useproof.com/pricing`}>Pricing</Button>
        </Item>
        <SubMenu label={`Resources`}>
          <Resources />
        </SubMenu>
      </List>
    </Nav>
  )
}

// Styled Components
const Nav = styled.nav`
  height: 100%;
  display: none;
  @media (min-width: 992px) {
    display: flex;
  }
`
const List = styled.ul`
  display: flex;
  list-style: none;
  height: 100%;
`
const Item = styled.li`
  height: 100%;
  margin-right: 2rem;
  position: relative;
  &:last-child {
    margin-right: 0;
  }
`
const Button = styled.a`
  height: 100%;
  display: flex;
  align-items: center;
  vertical-align: middle;
  font-size: 1rem;
  cursor: pointer;
  &:hover,
  &.visible {
    color: ${props => props.theme.color.blue["400"]};
  }
`
const SubNav = styled.nav`
  display: none;
  @media (min-width: 992px) {
    display: block;
    position: absolute;
    left: -2rem;
    top: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.1s;
    background-color: #fff;
    border: 1px solid ${props => props.theme.color.neutral["300"]};
    box-shadow: 0 8px 24px 0 rgba(52, 51, 62, 0.1);
    width: 27rem;
    padding: 2rem;
    z-index: 3;
    &.visible {
      visibility: visible;
      opacity: 1;
    }
    :before {
      content: "";
      display: block;
      position: absolute;
      top: -1rem;
      left: 2rem;
      border-left: 1rem solid transparent;
      border-right: 1rem solid transparent;
      border-bottom: 1rem solid ${props => props.theme.color.neutral["300"]};
    }
  }
`
const Chevron = styled.svg`
  width: 0.75rem;
  fill: none;
  margin-left: 0.7rem;
  stroke: ${props => props.theme.color.neutral["400"]};
  ${Button}:hover &,
  ${Button}.visible & {
    stroke: ${props => props.theme.color.blue["400"]};
  }
`
