import React from "react"
import styled from "styled-components"

// Components
import Top from "./top/index"
import Bottom from "./bottom/index"

export default () => {
  return (
    <Footer>
      <Top />
      <Bottom />
    </Footer>
  )
}

// Styled Components
const Footer = styled.footer`
  background-color: ${props => props.theme.color.neutral["700"]};
  color: #fff;
`
