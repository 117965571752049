import React from "react"
import styled from "styled-components"

export default ({ children, fullWidth, sm, md, lg, xl }) => (
  <Container fullWidth={fullWidth} sm={sm} md={md} lg={lg} xl={xl}>
    {children}
  </Container>
)

// Styled Components
const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  margin: 0 auto;
  ${props => `
        ${
          props.fullWidth
            ? `
            max-width: 100%;
        `
            : `
            max-width: calc(100% - 3rem);
        `
        }
        ${props.sm ? props.sm : ``};
        @media (min-width:768px) {
            ${props.md ? props.md : ``};
        }
        @media (min-width:992px) {
            ${props.lg ? props.lg : ``};
        }
        @media (min-width:1200px) {
            ${props.xl ? props.xl : ``};
            ${!props.fullWidth && `width: 1100px`};
        }
    `}
`
