export default {
  color: {
    neutral: {
      "5": "#FAFBFC",
      "10": "#F3F5F8",
      "15": "#EDF0F5",
      "300": "#C7D1E4",
      "400": "#99ABCE",
      "500": "#7D90B8",
      "600": "#435B8C",
      "700": "#08083d",
      "900": "#06062E",
    },
    blue: {
      "50": "#EAEDFF",
      "400": "#1E43FF",
      "600": "#0026ea",
    },
    pink: {
      "50": "#FFE0F1",
      "400": "#FF1493",
    },
    gray: {
      "50": "#96A6C9",
    },
    primary: {
      "100": "#4270FC",
    },
  },
}
