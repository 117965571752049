import React from "react"
import styled from "styled-components"

export default ({ title, link }) => {
  return <Button href={link}>{title}</Button>
}

// Styled Components
const Button = styled.a`
  background-color: ${props => props.theme.color.blue["400"]};
  color: #fff;
  text-align: center;
  width: 100%;
  display: block;
  padding: 1rem 0;
`
