import React from "react"
import styled from "styled-components"

// Components
import Container from "../../container.js"
import Logo from "./logo"
import Nav from "./nav"
import MobileNav from "./mobileNav/index"
import Cta from "./cta"

export default ({ pathPrefix, pageNumber, numberOfPages }) => {
  return (
    <Wrapper>
      <Container
        sm={`
                    display:flex;
                    height:100%;
                    align-items:center;
                    justify-content: space-between;
                `}
        xl={`
                    justify-content: start;
                `}
      >
        <Logo
          pathPrefix={pathPrefix}
          pageNumber={pageNumber}
          numberOfPages={numberOfPages}
        />
        <Nav />
        <Cta />
        <MobileNav />
      </Container>
    </Wrapper>
  )
}

// Styled Components
const Wrapper = styled.div`
  height: 4.5rem;
  background-color: #fff;
  border-bottom: 1px solid ${props => props.theme.color.neutral["300"]};
  position: relative;
  @media (min-width: 992px) {
    height: 5.5rem;
  }
`
