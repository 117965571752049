import React from "react"
import styled from "styled-components"

// Components
import Nav from "./nav"

export default ({ pathPrefix, category }) => {
  return (
    <Wrapper>
      <Nav pathPrefix={pathPrefix} category={category} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(min-content, auto));
  grid-template-rows: minmax(2.5rem, auto);
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.color.neutral["10"]};
  padding: 0 1.5rem;
  @media (min-width: 992px) {
    justify-content: center;
    grid-template-rows: minmax(3rem, auto);
  }
`
