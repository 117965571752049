import React from "react"

// Theme
import theme from "../../layout/theme"

// Layout Components
import Container from "../../container"

// Components
import Social from "./social"
import Copyright from "./copyright"

export default () => {
  return (
    <Container
      sm={`
                grid-template-columns:minmax(0,1fr);
                padding-top:2rem;
                padding-bottom:2rem;
                grid-row-gap:1rem;
                border-top:1px solid ${theme.color.neutral["600"]};
            `}
      md={`
                grid-template-columns:repeat(2, minmax(0,max-content));
                justify-content:space-between;
                align-items:center;
            `}
    >
      <Social />
      <Copyright />
    </Container>
  )
}
