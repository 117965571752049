import React, { useState } from "react"
import styled from "styled-components"

// Layout Components
import Container from "../container"

export default () => {
  const defaultFields = {
    email: ``,
  }

  const defaultMessage = `Sign Up`

  const [fields, setFields] = useState(defaultFields)

  const [message, setMessage] = useState(defaultMessage)

  const handleChange = e => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()

    const reset = setTimeout(() => {
      setMessage(defaultMessage)
      setFields(defaultFields)
    }, 1500)

    try {
      const { email } = fields

      const result = window.analytics && window.analytics.track("Newsletter Subscription", {
        email,
      })

      if (result) {
        setMessage(`Subscribed!`)

        return reset
      } else {
        setMessage(`Try again later`)

        return reset
      }
    } catch (e) {
      setMessage(`Try again later`)

      return reset
    }
  }
  return (
    <Section>
      <Container
        sm={`
                    grid-row-gap:1.5rem;
                    padding-top:3rem;
                    padding-bottom:3rem;
                `}
        lg={`
                    grid-template-columns:repeat(2,minmax(0,max-content));
                    grid-column-gap:1rem;
                    justify-content:space-between;
                    align-items:center;
                    padding-top:6rem;
                    padding-bottom:6rem;
                `}
      >
        <Header>
          <Title>{`Stay in the loop.`}</Title>
          <Description>{`Join our newsletter to get our insights before anyone else.`}</Description>
        </Header>
        <Form onSubmit={handleSubmit}>
          <Input
            type="email"
            name="email"
            value={fields.email}
            onChange={handleChange}
            placeholder={`Enter Your Email`}
            required
            aria-label={`Enter your email address to subscribe to our newsletter`}
          />
          <Button aria-label={`Subscribe to our newsletter`}>{message}</Button>
        </Form>
      </Container>
    </Section>
  )
}

// Styled Components
const Section = styled.section`
  background-color: ${props => props.theme.color.neutral["15"]};
`
const Header = styled.div`
  text-align: center;
  @media (min-width: 992px) {
    text-align: left;
  }
`
const Title = styled.h2`
  font-size: 1.5rem;
`
const Description = styled.p``
const Form = styled.form`
  width: 100%;
  display: flex;
  font-size: 0.85rem;
  justify-content: center;
  @media (min-width: 992px) {
    font-size: 1rem;
    justify-content: flex-end;
  }
`
const Input = styled.input`
  appearance: none;
  outline: 0;
  border: 1px solid ${props => props.theme.color.neutral["300"]};
  border-right: none;
  border-radius: 0.25rem 0 0 0.25rem;
  padding: 1rem;
  height: 2.25rem;
  width: 20rem;
  max-width: calc(100% - 6.25rem);
  line-height: 1;
  vertical-align: middle;
  @media (min-width: 992px) {
    max-width: calc(100% - 8.125rem);
  }
`
const Button = styled.button`
  appearance: none;
  border: none;
  color: #fff;
  transition: all 0.2s;
  background-color: ${props => props.theme.color.blue["400"]};
  border-radius: 0 0.25rem 0.25rem 0;
  cursor: pointer;
  outline: 0;
  width: 6.25rem;
  height: 2.25rem;
  &:hover {
    background-color: ${props => props.theme.color.blue["600"]};
  }
  @media (min-width: 992px) {
    width: 8.125rem;
  }
`
