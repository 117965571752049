import React, { useRef, useState } from "react"
import Modal from "react-modal"
import styled from "styled-components"
import classNames from "classnames"

// Utils
import handleElementClose from "../../../../utils/handleElementClose"

// Modal Settings
import { style } from "./settings"

// Layout Components
import Container from "../../../container"

// Components
import Products from "../products"
import Secondary from "./secondary"
import Cta from "./cta"

export default () => {
  const secondary = [
    {
      title: `Pricing`,
      link: `https://useproof.com/pricing`,
    },
    {
      title: `eBook`,
      link: `https://useproof.com/personalization-guide`,
    },
    {
      title: `Case Studies`,
      link: `https://useproof.com/customers`,
    },
    {
      title: `Scale or Die`,
      link: `https://useproof.com/scaleordie`,
    },
    {
      title: `Playbooks`,
      link: `https://useproof.com/playbooks`,
    }, {
      title: `Blog`,
      link: `https://blog.useproof.com`,
    },
  ]

  const [isOpen, setOpen] = useState(false)

  const className = classNames("modal", "modal--nav")

  const button = useRef(null)
  const wrapper = useRef(null)

  handleElementClose({
    elements: [wrapper, button],
    state: isOpen,
    setState: setOpen,
    hiddenDesktop: true,
  })
  return (
    <React.Fragment>
      <Toggle
        ref={button}
        onClick={() => setOpen(!isOpen)}
        aria-label={`Toggle Navigation Menu`}
      >
        <svg
          height="20"
          width="20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M2.5 10h15M2.5 5h15M2.5 15h15"
            stroke="#435B8C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Toggle>
      <Modal
        style={style}
        isOpen={isOpen}
        portalClassName={className}
        closeTimeoutMS={300}
      >
        <Wrapper ref={wrapper}>
          <Container
            sm={`
                            padding-top:1.5rem;
                            padding-bottom:1.5rem;
                            grid-row-gap:2rem;
                            width:288px;
                        `}
          >
            <Products />
            <Secondary items={secondary} />
            <Cta
              title={`Increase my conversions`}
              link={`https://useproof.com/pulse?utm_source=blog&utm_campaign=blog_organic&utm_medium=organic&utm_content=blog_nav`}
            />
          </Container>
        </Wrapper>
      </Modal>
    </React.Fragment>
  )
}

// Styled Components
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`
const Toggle = styled.a`
  display: flex;
  background-color: transparent;
  outline: 0;
  border: none;
  padding: 0;
  cursor: pointer;
  z-index: 2;
  @media (min-width: 992px) {
    display: none;
  }
`
