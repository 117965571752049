import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

// Hooks
import useResourcesBg from "../../../hooks/useResourcesBg"

export default () => {
  const items = [
    {
      title: `Watch Scale or Die`,
      link: `https://useproof.com/scaleordie`,
    },
    {
      title: `Blog`,
      link: `https://blog.useproof.com`,
    },
    {
      title: `Playbooks`,
      link: `https://useproof.com/playbooks`,
    },
    {
      title: `Case Studies`,
      link: `https://useproof.com/customers`,
    },
  ]

  return (
    <Wrapper>
      <Cta href={`https://useproof.com/personalization-guide`}>
        <Background fixed={useResourcesBg()} />
        <Title>{`Learn Personalization`}</Title>
        <Caption>{`Read how top marketers personalize their company's website in 2020.`}</Caption>
      </Cta>
      <List>
        <Item>{`Learn more`}</Item>
        {items.map(({ title, link }, index) => (
          <Item key={index}>
            <Link href={link}>{title}</Link>
          </Item>
        ))}
      </List>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 12rem) minmax(0, 1fr);
  grid-column-gap: 1.5rem;
  line-height: 1.5;
`
const Cta = styled.a`
  display: grid;
  align-content: start;
  grid-template-columns: minmax(0, 1fr);
  grid-row-gap: 1rem;
  overflow: hidden;
  border-top-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background-color: ${props => props.theme.color.neutral["10"]};
  &:hover {
    background-color: ${props => props.theme.color.blue["50"]};
  }
  padding-bottom: 1rem;
  min-height: 16rem;
`
const Background = styled(Img)``
const Title = styled.div`
  padding: 0 1rem;
  color: ${props => props.theme.color.blue["400"]};
`
const Caption = styled.div`
  padding: 0 1rem;
  font-size: 0.75em;
`
const List = styled.ul`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  align-content: start;
  grid-row-gap: 0.5rem;
`
const Item = styled.li``
const Link = styled.a`
  color: ${props => props.theme.color.blue["400"]};
`
